// Needs to be in unsecure build.
import { CompanyNavigations } from './Navigations/CompanyNavigations';
import MobileHeaderBeforeLogin from './Navigations/MobileHeaderBeforeLogin';

import React from 'react';

const NotFound = () => {
    return (
        <div className="container-fluid no-padding--left mlr-xs-none">
            <CompanyNavigations />

            <MobileHeaderBeforeLogin />

            <div
                className="common-container pt-3 mt-3"
                style={{ marginLeft: '10%' }}
            >
                <h1 className="mb-4">Page Not Found</h1>
                <p>We could not find what you are looking for.</p>

                <a className="btn btn-secondary" href="/">
                    Back to the Dashboard
                </a>
            </div>
        </div>
    );
};

export default NotFound;
